import { useEffect } from "react";
import portrait from "./media/about/portrait.webp";
import AOS from "aos";
import "aos/dist/aos.css";
function About() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className=" bg-black font-font-family  ">
      <div className=" md:grid grid-cols-2  ">
        <div className=" col-span-1 border-solid border-t-2 md:border-b-2 border-b-0 md:border-r-2 border-r-0 md:py-14 py-0 md:pb-14 pb-0  md:px-14 px-8 md:pt-14 pt-8 border-white">
          <img
            data-aos="fade-right"
            data-aos-duration="1500"
            src={portrait}
            alt="portrait"
            className=" "
          ></img>
        </div>
        <div className=" col-span-1   md:border-t-2 border-t-0 border-b-2 md:py-14 py-0 md:pb-0 pb-8 md:px-14 px-8  border-white ">
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className=" bg-white "
          >
            <h1 className=" text-6xl italic  text-black md:pl-12 pl-6  md:pt-28 pt-14">
              About
            </h1>
            <p className="xl:text-2xl md:text-base text-sm font-font-family  text-slate-500 md:pl-12 pl-6 md:pr-28 pr-4 md:pt-10 pt-6  ">
              Vullnet Muharemi was born in Tetovo, North Macedonia. At the age
              of 18, he moved to the UK where he received a BA in Politics from
              Oxford Brookes University and an MA from Exeter University.
              Influenced by the French philosopher Jean Baudrillard, Vullnet’s
              interest in photography in general and street photography in
              particular started by asking questions: ‘How social media shapes
              or distorts our perceptions of images and of REALITY itself ? Is
              it the context and concept behind the image that produces meaning?
              What is the relationship between being and appearing ?How does a
              street photo situate its viewers ?Should photography be divorced
              from context? Vullnet Muharemi is also an author and poet. After
              spending time in Paris, France, he now lives in Tetovo, North
              Macedonia.
            </p>
            <div className=" mt-10 md:ml-12 ml-6 md:pb-28 pb-10 ">
              <a
                href="mailto:vullnet-muharemi33@hotmail.com"
                className=" text-white text-xl bg-black  py-4 px-10  "
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
