import React, { useEffect, useRef, useState } from "react";
import photo1 from "./media/photo1.webp";
import photo2 from "./media/photo2.webp";
import photo3 from "./media/photo3.webp";

function Hero() {
  const containerRef = useRef(null);
  const leftPhotoRef = useRef(null);
  const rightPhotoRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  const firstHeadingRef = useRef(null);
  const secondHeadingRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const leftPhoto = leftPhotoRef.current;
    const rightPhoto = rightPhotoRef.current;

    const handleMouseMove = (event) => {
      const containerWidth = container.offsetWidth;
      const mouseX = event.clientX;
      const center = containerWidth / 2;
      const maxTranslate = 180; // Adjust this value to control the maximum translation

      const offsetX = (mouseX - center) / center; // Calculate the offset relative to the center

      // Apply translation to the left and right photos
      leftPhoto.style.transform = `translateX(${
        -maxTranslate * offsetX
      }px) rotate(${rotation}deg)`;
      rightPhoto.style.transform = `translateX(${
        -maxTranslate * offsetX
      }px) rotate(${rotation}deg)`;
    };

    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, [rotation]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const firstHeading = firstHeadingRef.current;
      const secondHeading = secondHeadingRef.current;

      // Calculate the position and offset based on scroll
      const firstHeadingPosition = scrollPosition * 2; // Adjust the scroll speed as needed
      const secondHeadingPosition = -scrollPosition * 2; // Adjust the scroll speed as needed
      const opacity = 1 - scrollPosition / (windowHeight / 1); // Adjust the scroll range for opacity

      // Update the position of the text elements
      firstHeading.style.transform = `translateX(${firstHeadingPosition}px)`;
      firstHeading.style.opacity = opacity;
      secondHeading.style.transform = `translateX(${secondHeadingPosition}px)`;
      secondHeading.style.opacity = opacity;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-black md:h-screen h-full md:w-full w-auto font-font-family  overflow-x-hidden ">
      <div
        className="relative block  flex items-center justify-center md:h-full h-full  md:w-full w-auto md:mt-0 mt-60 md:mb-0 mb-60"
        ref={containerRef}
      >
        {/* Moving background images */}
        <div className="absolute -rotate-12 z-0 md:left-96 left-2 flex justify-center items-center">
          <img
            src={photo2}
            alt="Background Image 1"
            className=" md:h-128 h-64  md:w-94 w-28 md:mt-24 mt-12"
            ref={leftPhotoRef}
          />
        </div>
        <div className="absolute rotate-12 z-0 md:right-96 right-2 flex justify-center items-center">
          <img
            src={photo3}
            alt="Background Image 2"
            className=" md:h-128 h-64  md:w-94 w-28 md:mt-24 mt-12"
            ref={rightPhotoRef}
          />
        </div>
        {/* Center image */}
        <div className="absolute z-10">
          <img
            src={photo1}
            alt="Center Image"
            className="md:h-130 h-72  md:w-128 w-64 "
          />
        </div>
        <div>
          <h1
            className="absolute md:text-8xl text-5xl tracking-widest3  text-white left-4 md:top-52 -top-44 z-20"
            ref={firstHeadingRef}
          >
            Vullnet
          </h1>
          <h1
            className="absolute  md:text-8xl text-5xl tracking-widest3  text-white right-4 md:bottom-36 -bottom-44 z-20"
            ref={secondHeadingRef}
          >
            Muharemi
          </h1>
        </div>
        <div>
          <h1 className="absolute md:tracking-widest1 tracking-widest2  md:text-8xl text-3xl  text-white left-4  z-20">
            Photography
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
