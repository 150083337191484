import H1H from "./media/Black/H1H.webp";
import H2H from "./media/Black/H2H.webp";
import H5H from "./media/Black/H5H.webp";
import H6H from "./media/Black/H6H.webp";
import S1S from "./media/Black/S1S.webp";
import S2S from "./media/Black/S2S.webp";
import S3S from "./media/Black/S3S.webp";
import S4S from "./media/Black/S4S.webp";
import S5S from "./media/Black/S5S.webp";
import S6S from "./media/Black/S6S.webp";
import S7S from "./media/Black/S7S.webp";
import S8S from "./media/Black/S8S.webp";
import L1L from "./media/Black/L1L.webp";
import L2L from "./media/Black/L2L.webp";
import L3L from "./media/Black/L3L.webp";
import L4L from "./media/Black/L4L.webp";
import L5L from "./media/Black/L5L.webp";
import L6L from "./media/Black/L6L.webp";
import L7L from "./media/Black/L7L.webp";
import L8L from "./media/Black/L8L.webp";
import L9L from "./media/Black/L9L.webp";
import L10L from "./media/Black/L10L.webp";
import L11L from "./media/Black/L11L.webp";
import L12L from "./media/Black/L12L.webp";
function Black() {
  return (
    <div className=" bg-black">
      <div>
        <section class="text-white font-font-family">
          <div class="text-center w-full mt-20 mb-20">
            <h1 class="md:text-8xl text-5xl italic  mb-4 text-white">
              Black&White
            </h1>
          </div>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center  md:mr-14 mr-4">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={H1H}
                alt="La Boverie Museum - Liege"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                La Boverie Museum - Liege
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={H2H}
                alt="Jardin du Palais - Royal, Paris"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Jardin du Palais - Royal, Paris
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L1L}
              alt="Collioure"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Collioure
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L2L}
              alt="Montmartre, Paris"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Montmartre, Paris
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={S1S}
                alt="Jardin du Palais - Royal, Paris"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Jardin du Palais - Royal, Paris
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={S2S}
                alt="Montmartre - Paris"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Montmartre - Paris
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L7L}
              alt="Maastricht"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Maastricht
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L8L}
              alt="Guggenheim Museum, Bilbao"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Guggenheim Museum, Bilbao
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={S3S}
                alt="Toledo"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Toledo
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={S4S}
                alt="Paris"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Paris
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L9L}
              alt="Chateauneuf du Pape"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Chateauneuf du Pape
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L10L}
              alt="Barcelona"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Barcelona
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={S5S}
                alt="Antwerp"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Antwerp
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={S6S}
                alt=" Ile Sainte Marguerite, Cannes"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Ile Sainte Marguerite, Cannes
              </h1>
            </div>
          </div>
          <div className="flex justify-center mt-14 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L3L}
              alt="Toulouse"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Toulouse
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L4L}
              alt="Toledo, Spain"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Toledo, Spain
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={H5H}
                alt="Toledo, Spain"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Toledo, Spain
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={H6H}
                alt="Valladolid"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Valladolid
              </h1>
            </div>
          </div>
          <div className="flex justify-center mt-14 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L5L}
              alt="Nice, France"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Nice, France
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L6L}
              alt="Innsbruck"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Innsbruck
          </h1>
          <div className="grid grid-cols-2 space-x-2 md:ml-14 ml-4 md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={S8S}
                alt="Salamanca"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Salamanca
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={S7S}
                alt=" Mont Saint Michel"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Mont Saint Michel
              </h1>
            </div>
          </div>
          <div className="flex justify-center mt-14 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L11L}
              alt="Verona"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Verona
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={L12L}
              alt="Carcassonne"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Carcassonne
          </h1>
        </section>
      </div>
    </div>
  );
}

export default Black;
