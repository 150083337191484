import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import black from "./media/cards/black.webp";
import fragments from "./media/cards/fragments.webp";
import visible from "./media/cards/visible.webp";
import solitude from "./media/cards/solitude.webp";
function Cards() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className=" bg-black mt-24 mb-20 flex justify-center font-font-family  ">
        <div className=" md:grid grid-cols-4 md:max-w-fit max-w-xs ">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            class="cols-span-1 "
          >
            <img
              class=" md:h-128 h-96 md:w-96 w-96"
              src={black}
              alt=" Black and White"
            />
            <div className=" flex justify-center mt-4 md:hover:scale-105 hover:scale-100">
              <Link
                onClick={() => {
                  window.location.href = "/blackandwhite";
                  window.scrollTo({ top: 0, left: 0 });
                }}
                className=" text-2xl  border-2  bg-white py-2 px-16 mb-12 md:-ml-10 -ml-0 shadow-3xl shadow-slate-200"
              >
                View
              </Link>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            class=" md:ml-6 ml-0 cols-span-1  "
          >
            <img
              class="md:h-128 h-96 md:w-96 w-96"
              src={fragments}
              alt=" Fragments"
            />
            <div className=" flex justify-center mt-4 md:hover:scale-105 hover:scale-100">
              <Link
                onClick={() => {
                  window.location.href = "/fragments";
                  window.scrollTo({ top: 0, left: 0 });
                }}
                className=" text-xl  border-2  bg-white py-2 px-16 mb-12  shadow-3xl shadow-slate-200"
              >
                View
              </Link>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            class="cols-span-1 md:ml-12 ml-0"
          >
            <img
              class="md:h-128 h-96 md:w-96 w-96"
              src={visible}
              alt=" Visible"
            />
            <div className=" flex justify-center mt-4 md:hover:scale-105 hover:scale-100 ">
              <Link
                onClick={() => {
                  window.location.href = "/exhibition";
                  window.scrollTo({ top: 0, left: 0 });
                }}
                className=" text-xl  border-2  bg-white py-2 px-16 mb-12 shadow-3xl shadow-slate-200"
              >
                View
              </Link>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            class="cols-span-1 md:ml-12 ml-0"
          >
            <img
              class="md:h-128 h-96 md:w-96 w-96"
              src={solitude}
              alt=" Solitude"
            />
            <div className=" flex justify-center mt-4 md:hover:scale-105 hover:scale-100 ">
              <Link
                onClick={() => {
                  window.location.href = "/solitude";
                  window.scrollTo({ top: 0, left: 0 });
                }}
                className=" text-xl  border-2  bg-white py-2 px-16 mb-12 shadow-3xl shadow-slate-200"
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
