function Footer() {
  return (
    <div className=" bg-black h-auto font-font-family">
      <div class="mt-20 border-t border-gray-400 text-center">
        <p class="mt-12 pb-12 md:text-base text-sm md:pr-0 pr-12 md:pl-0 pl-12 text-white  text-center ">
          Copyright ©{" "}
          <span className=" text-white font-bold ">Vullnet Muharemi.</span>{" "}
          Powered by{" "}
          <div className="hover:scale-105  inline-block">
            <a
              href="https://www.instagram.com/checkmate.io/"
              target="{_blank}"
              className=" text-white text-lg font-bold "
            >
              Checkmate
            </a>
          </div>
          .
        </p>
      </div>
    </div>
  );
}

export default Footer;
