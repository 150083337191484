import React from "react";
import { Twirl as Hamburger } from "hamburger-react";
import "./index.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "./media/logo.png";
function Navbar() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 860) {
        setIsNavExpanded(false);
      }
      if (window.innerWidth < 860) {
        setIsNavExpanded(false);
      }
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCurrentPath = () => {
    const path = window.location.pathname;
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };

  const currentPath = getCurrentPath();

  return (
    <div className="App">
      <nav className="bg-black border-b-2 border-white dark:bg-black w-full">
        <div className="flex flex-wrap items-center justify-between md:p-4">
          <Link
            onClick={() => {
              window.location.href = "/";
              window.scrollTo({ top: 0, left: 0 });
            }}
            className="flex items-start mt-6 mb-6 ml-2"
          >
            <img src={logo} className="h-20" alt="Vullnet Muharemi Logo" />
          </Link>
          <div className="md:hidden sm:block mr-2">
            <Hamburger
              size={38}
              toggled={isNavExpanded}
              toggle={setIsNavExpanded}
              distance="lg"
              color="White"
              duration={0.6}
            ></Hamburger>
          </div>
          <div
            className="hidden  md:block  md:w-auto font-font-family "
            id="navbar-default"
          >
            <ul className="font-font-family flex flex-col p-4 md:p-0 mt-4  md:text-2xl  md:flex-row md:space-x-8 md:mt-0 md:text-white text-white bg-black md:mr-3   ">
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/blackandwhite";
                    window.scrollTo({ top: 0, left: 0 });
                  }}
                  class={`block py-2 pl-3  text-white  dark:text-white hover:text-slate-300 ${
                    currentPath === "/blackandwhite"
                      ? "text-slate-300   border-slate-300 "
                      : ""
                  } `}
                  aria-current="page"
                >
                  Black and White
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/fragments";
                    window.scrollTo({ top: 0, left: 0 });
                  }}
                  class={`block py-2 pl-3  text-white  dark:text-white hover:text-slate-300 ${
                    currentPath === "/fragments"
                      ? "text-slate-300   border-slate-300 "
                      : ""
                  } `}
                >
                  Fragments
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/exhibition";
                    window.scrollTo({ top: 0, left: 0 });
                  }}
                  className={`block py-2 pl-3  text-white  dark:text-white hover:text-slate-300 ${
                    currentPath === "/exhibition"
                      ? "text-slate-300   border-slate-300 "
                      : ""
                  } `}
                >
                  Visible & Invisible
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/solitude";
                    window.scrollTo({ top: 0, left: 0 });
                  }}
                  className={`block py-2 pl-3  text-white  dark:text-white hover:text-slate-300 ${
                    currentPath === "/solitude"
                      ? "text-slate-300   border-slate-300 "
                      : ""
                  } `}
                >
                  Solitude
                </Link>
              </li>
            </ul>
          </div>
          <div
            className={isNavExpanded ? "block w-full " : "hidden"}
            id="navbar-default"
          >
            <ul className="font-font-family flex flex-col border-t-2 border-white pt-4 pb-4 mt-4 md:text-2xl  md:flex-row  md:text-white text-white bg-black    ">
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/blackandwhite";
                    window.scrollTo({ top: 0, left: 0 });
                    setIsNavExpanded(false);
                  }}
                  className="block py-2 pl-3  text-white  dark:text-white "
                  aria-current="page"
                >
                  Black and White
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/fragments";
                    window.scrollTo({ top: 0, left: 0 });
                    setIsNavExpanded(false);
                  }}
                  className="block py-2 pl-3  text-white  dark:text-white"
                >
                  Fragments
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/exhibition";
                    window.scrollTo({ top: 0, left: 0 });
                    setIsNavExpanded(false);
                  }}
                  className="block py-2 pl-3  text-white  dark:text-white"
                >
                  Visible & Invisible
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.location.href = "/solitude";
                    window.scrollTo({ top: 0, left: 0 });
                    setIsNavExpanded(false);
                  }}
                  className="block py-2 pl-3  text-white  dark:text-white"
                >
                  Solitude
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
